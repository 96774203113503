<template>
    <div class="home">
        <banner isHome="true"></banner>
        <div class="site-content animate">
            <!--通知栏-->
            <div class="notify">
                <quote>{{notice}}</quote>
            </div>
            <!--焦点图-->
            <div class="top-feature">
                <section-title>
                    <div style="display: flex;align-items: flex-end;" @click="gotoMusic">音药狂魔<small-ico></small-ico></div>
                </section-title>
                <div class="feature-content">
                    <div class="feature-item" v-for="item in features" :key="item.id">
                        <Feature :data="item"></Feature>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Banner from '@/components/banner'
    import Feature from '@/components/feature'
    import sectionTitle from '@/components/section-title'
    import SmallIco from '@/components/small-ico'
    import Quote from '@/components/quote'
    import {fetchFocus} from '../api'

    export default {
        name: 'Home',
        data() {
            return {
                features: [],
                currPage: 1,
                hasNextPage: false
            }
        },
        components: {
            Banner,
            Feature,
            sectionTitle,
            SmallIco,
            Quote
        },
        computed: {
            notice() {
                return this.$store.getters.notice
            }
        },
        methods: {
            gotoMusic() {
                this.$router.push('/kuXMusic/index')
            },
            // 聚焦
            fetchFocus() {
                fetchFocus().then(res => {
                    this.features = res.data || []
                }).catch(err => {
                    console.log(err)
                })
            }
        },
        mounted() {
            this.fetchFocus();
        }
    }
</script>
<style scoped lang="less">

    .site-content {
        .notify {
            margin: 60px 0;
            border-radius: 3px;
            & > div {
                padding: 20px;
            }
        }

    }
    
    .top-feature {
        width: 100%;
        height: auto;
        margin-top: 30px;

        .feature-content {
            margin-top: 10px;
            // display: flex;
            display: none;
            justify-content: space-between;
            position: relative;

            .feature-item {
                width: 32.9%;
            }
        }
    }

    .site-main {
        padding-top: 80px;

        &.search {
            padding-top: 0;
        }
    }

    .more{
        margin: 50px 0;
        .more-btn{
            width: 100px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            color: #ADADAD;
            border: 1px solid #ADADAD;
            border-radius: 20px;
            margin: 0 auto;
            cursor: pointer;
            &:hover{
                color: #8fd0cc;
                border: 1px dashed #8fd0cc;
            }
        }
    }

    /******/
    @media (max-width: 800px) {
        .top-feature {
            .feature-content {
                display: block;
            }
        }
        .site-main {
            padding-top: 40px;
        }

        .site-content {
            .notify {
                margin: 30px 0 0 0;
            }

        }
    }

    @media (max-width: 600px) {
        .top-feature {
            .feature-content {
                display: none;
            }
        }
    }

    /******/
</style>
